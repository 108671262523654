import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../css/pages/updates.scss"


let faces = [
  "⋟﹏⋞",
  "⚆ᗝ⚆",
  "●﹏●",
  "⊙△⊙",
  "˚▱˚"
]

let faceTmp = faces[Math.floor(Math.random() * faces.length)];

const SecondPage = () => (
  <Layout showFooter={false} glitchedHeader={true}>
    <SEO title="404" />
    <div className="updates">
      <div className="message">
        <h3 className="face">{faceTmp}</h3>
        <h3>Oh wow embarrassing, but this page doesn't exist!</h3>
        <Link to="/" className="button">Why not go Home?</Link>
      </div>
    </div>
  </Layout>
)

export default SecondPage
